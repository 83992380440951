import {
  getOneDatabase, getOneEndpoint,
  listEndpoint, listFieldsDatabase,
  listParamsEndpoint,
} from 'api-lofty';
import {restoreComponentToDefault, validDestinationForComponent} from '../../util/grapeJsCanvasFunctions';
import {
  databaseFieldsGetComponent,
  getAllFieldsDirectForCustomParams,
  getFieldsBucket,
} from '../../util/databaseFieldsComponent';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;
  // eslint-disable-next-line consistent-return

  function exhaustiveSearchVariable(idVariable, section) {
    let foundData;
    if (section === 'local') {
      foundData = opts.localVars.find((index) => (index._id === idVariable));
    } else {
      foundData = opts.globalVars.find((index) => (index._id === idVariable));
    }
    if (foundData) {
      return foundData;
    }
    return null;
  }

  function typeOfVariable(component, data) {
    const searchVariable = data.find((index) => (index._id === component.value));
    return searchVariable;
  }
  /**
    =========================================================
    * Handle Open Custom Endpoint Modal
    =========================================================

    * Opens the modal to manage the custom endpoint.
    * @param {*fields From Database*} fieldData
    * @param {*current Component*} model
    * @param {*local and global variables*} variables
    * @param {*params from the actual page*} paramData
    * May have empty values if not found.
    * Only for custom

    =========================================================
  */
  function handleButtonClickOpenCustomEndpointManager(model, variables, paramData, fieldData) {
    const {handleCustomEndpointManager} = opts;
    handleCustomEndpointManager(
      variables,
      fieldData,
      paramData,
      model,
    );
  }

  /**
    =========================================================
    * Trait Button to Call Modal Custom Endpoint
    =========================================================

    * Creates the trait for the component
    * @param {*fields From Database*} fieldData
    * @param {*current Component*} model
    * @param {*local and global variables*} variables
    * @param {*params from the actual page*} paramData
    * May have empty values if not found.
    * Only for custom

    =========================================================
  */

  function modalCustomEndpointCall(model, variables, paramData, fieldData) {
    const traitCustomEndpointHandler = {
      name: 'buttonCustomEndpointModal',
      type: 'button',
      text: 'Manejar Parametros de Servicio',
      full: true,
      command: () => handleButtonClickOpenCustomEndpointManager(
        model,
        variables,
        paramData,
        fieldData,
      ),
    };
    model.addTrait(traitCustomEndpointHandler);
  }

  /**
      =========================================================
      * Handle Capture Data for Custom Endpoint
      =========================================================

      * Captures all data if found.
      * @param {*Verifies if parent is a ReadDB Component*} findReadDBParent
      * @param {*current Component*} model
      * @param {*verifies if variables exists*} hasVariables
      * @param {*verifies if page has params*} findParams
      * May have empty values if not found.
      * Only for custom

      =========================================================
    */
  async function captureDataForCustomEndpointCall(
    model,
    hasVariables,
    findReadDBParent,
  ) {
    try {
      model.removeTrait('buttonCustomEndpointModal');
      let dbFromOrigin;
      const {token, idProject} = opts;
      if (model.attributes.localVars) {
        dbFromOrigin = exhaustiveSearchVariable(model.attributes.localVars, 'local');
      } else {
        dbFromOrigin = exhaustiveSearchVariable(model.attributes.globalVars, 'global');
      }
      // let getPageParams = [];
      // let variableData = [];
      let relationFields = [];
      // if (findParams) {
      //   getPageParams = await getAllPageParams(token, idPage);
      // }
      if (findReadDBParent) {
        relationFields = await getAllFieldsDirectForCustomParams(
          dbFromOrigin.idDatabase,
          token,
          idProject,
        );
      }
      modalCustomEndpointCall(model, [], [], relationFields);
    } catch (error) {
      // console.log(error);
    }
  }

  function addTrait(model, type, answerValue) {
    if (type === 'local') {
      const variableData = opts.localVars;
      const optionData = variableData.filter((variable) => (variable.type === 'cartArray' || variable.typeValue === 'arrayDatabase')).map((index) => ({name: index.label, value: index._id}));
      const traitVariables = {
        type: 'select',
        name: 'localVars',
        label: 'Variables',
        changeProp: 1,
        options: optionData,
        default: answerValue || '',
      };
      model.addTrait(traitVariables);
    } else {
      const variableData = opts.globalVars;
      const optionData = variableData.filter((variable) => (variable.typeValue === 'cartArray' || variable.typeValue === 'arrayDatabase')).map((index) => ({name: index.label, value: index._id}));
      const traitVariables = {
        type: 'select',
        name: 'globalVars',
        label: 'Variables',
        changeProp: 1,
        options: optionData,
        default: answerValue || '',
      };
      model.addTrait(traitVariables);
    }
  }

  async function getEndpointInfoFromVariable(model, id) {
    const {token} = opts;
    const resData = await listEndpoint({token, idDatabase: id});
    const values = [];
    model.removeTrait('endpointSelect');
    model.removeTrait('buttonCustomEndpointModal');
    if (resData.data.length > 0) {
      // eslint-disable-next-line array-callback-return
      resData.data.forEach((index) => {
        if (
          index.typeFunction === 'customList' || index.typeFunction === 'list'
        ) {
          values.push({value: index._id, name: `${index?.url} -- ${index?.typeFunction}`});
        }
      });
      if (!model.getTrait('endpointSelect')) {
        const traitAddition = {
          type: 'select',
          name: 'endpointSelect',
          label: 'Seleccion de Servicio',
          changeProp: 1,
          options: values,
          default: model.attributes?.endpointSelect || '',
        };
        model.addTrait(traitAddition);
      }
    }
  }

  async function getEndpointAnalysisForParams(model, id) {
    const {token} = opts;
    const endpoint = await getOneEndpoint({token, _id: id});
    if (endpoint.data.typeFunction === 'customList') {
      const getParamData = await listParamsEndpoint({
        idEndpoint: id,
        idProject: endpoint.data.idProject,
        token,
      });
      if (getParamData.data.length > 0) {
        // const paramValues = getParamData.data.map((index) => ({
        //   paramendpoint: {
        //     value: index.name,
        //     label: index.label,
        //     type: index.type,
        //     stringDefaultValues: index?.stringDefaultValues || [],
        //   },
        //   state: index?.stringDefaultValues?.length > 0 ? 'static' : 'useparams',
        // }));
        // model.addAttributes({customParams: paramValues});
        captureDataForCustomEndpointCall(model, true, true);
      }
    }
  }

  async function addCarouselHTML(model, type) {
    try {
      // console.log(model);
      let selectGroupOfVars = [];
      if (type === 'localVars') {
        selectGroupOfVars = opts.localVars;
      } else {
        selectGroupOfVars = opts.globalVars;
      }
      const getTypeVariable = model.getTrait(type).attributes.value;
      const getOption = model.getTrait(type).attributes.options;
      const filterData = getOption.find((obj) => (obj.value === getTypeVariable));
      const searchType = typeOfVariable(filterData, selectGroupOfVars);
      const {token, idProject} = opts;
      const listFieldsFromResponse = await listFieldsDatabase({
        token, idDatabase: searchType.idDatabase, idProject,
      });
      const typeDB = await getOneDatabase({token, _id: searchType.idDatabase});
      let resFieldsReact = databaseFieldsGetComponent(listFieldsFromResponse.data);
      if (typeDB.data.isBucket) {
        const bucketFields = getFieldsBucket();
        resFieldsReact += bucketFields;
      }

      let htmlAdder = `
                <div data-gjs-slugLofty="itemCartData" data-gjs-name="Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}" data-gjs-draggable="false">
                  ${resFieldsReact}
            `;
      if (searchType?.relationDatabase) {
        await getEndpointInfoFromVariable(model, searchType?.relationDatabase);
        const relationfields = await listFieldsDatabase({
          token, idDatabase: searchType.relationDatabase, idProject,
        });
        const resFieldsFromRelation = databaseFieldsGetComponent(relationfields.data);
        htmlAdder += `
          <div data-gjs-slugLofty="itemRelationCartData" data-gjs-name="Informacion de Relacion" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
          ${resFieldsFromRelation}
          </div>
        `;
      }
      if (searchType.type === 'cartArray' || searchType.typeValue === 'cartArray') {
        htmlAdder += `
        <div data-gjs-slugLofty="quantityItemCartData" data-gjs-name="Cantidad del Item Listado de Arreglo" style="padding: 10px;"  data-gjs-resizable="{bc: 1}">
          Cantidad: 
        </div>`;
      }
      htmlAdder += '</div>';
      const htmlCarousel = `
              <div
                data-gjs-droppable="false"
                data-gjs-slugLofty="cartCarouselListSection"
                data-gjs-name="Seccion de Items del Carrito"
                style="padding: 10px; width: 10%; display: flex; flex-direction: column; justify-content: center; align-items: center;"
                data-gjs-resizable="{bc: 1}"
                data-gjs-draggable="false"
              >
                <img
                  data-gjs-slugLofty="dynImage"
                  data-gjs-stylable="false"
                  data-gjs-draggable="false"
                  data-gjs-droppable="false"
                  data-gjs-resizable="false"
                  data-gjs-editable="false"
                  data-gjs-highlightable="false"
                  data-gjs-selectable="false"
                  style="padding: 5px; width: 100%; height: auto; margin: 5px"
                  alt=""
                  src="https://placehold.co/100x50"
                />
                <img
                  data-gjs-slugLofty="dynImage"
                  data-gjs-stylable="false"
                  data-gjs-draggable="false"
                  data-gjs-droppable="false"
                  data-gjs-resizable="false"
                  data-gjs-editable="false"
                  data-gjs-highlightable="false"
                  data-gjs-selectable="false"
                  style="padding: 5px; width: 100%; height: auto; margin: 5px"
                  alt=""
                  src="https://placehold.co/100x50"
                />
                <img
                  data-gjs-slugLofty="dynImage"
                  data-gjs-stylable="false"
                  data-gjs-draggable="false"
                  data-gjs-droppable="false"
                  data-gjs-resizable="false"
                  data-gjs-editable="false"
                  data-gjs-highlightable="false"
                  data-gjs-selectable="false"
                  style="padding: 5px; width: 100%; height: auto; margin: 5px"
                  alt=""
                  src="https://placehold.co/100x50"
                />
              </div>
              <div
                data-gjs-droppable="false"
                data-gjs-slugLofty="showCartItemImage"
                data-gjs-name="Multimedia del Item"
                style="padding: 10px; width: 50%"
                data-gjs-resizable="{bc: 1}"
                data-gjs-draggable="false"
              >
              </div>
              <div
                data-gjs-droppable="false"
                data-gjs-slugLofty="cartItemInfoRender"
                data-gjs-name="Seccion de Informacion del Item del Carrito"
                style="padding: 10px; width: 40%"
                data-gjs-resizable="{bc: 1}"
                data-gjs-draggable="false"
              >
                ${htmlAdder}
              </div>
            `;

      // Main components
      model.append(htmlCarousel);
      const getModelData = model.get('components');

      // Cart Component
      const getMainCartSection = getModelData.find((index) => (index.attributes.sluglofty === 'cartItemInfoRender'));
      const getItemData = getMainCartSection.get('components');
      getItemData.forEach((index) => index.set({draggable: (e, d) => validDestinationForComponent(e, d, 'cartItemInfoRender')}));

      // Items of Cart
      const getMainCart = getItemData?.find((index) => (index.attributes.sluglofty === 'itemCartData'));
      const listData = getMainCart.get('components');
      listData.forEach((index) => index.set({draggable: (e, d) => validDestinationForComponent(e, d, 'itemCartData')}));

      // RelationData if exists
      const getRelationCart = listData?.find((index) => (index.attributes.sluglofty === 'itemRelationCartData'));
      if (getRelationCart) {
        const listRelationData = getRelationCart?.get('components');
        listRelationData?.forEach((index) => index.set({draggable: (e, d) => validDestinationForComponent(e, d, 'itemRelationCartData')}));
      }
    } catch (error) {
      // console.log(error);
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [{
          type: 'select', // Type of the trait
          label: 'Tipo de variable', // The label you will see in Settings
          name: 'variableHandle',
          options: [
            {value: 'local', name: 'Local'},
            {value: 'global', name: 'Global'},
          ],
          changeProp: 1,
        }],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === ('carouselCartViewer')) {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, 'change:variableHandle', this.updateComponent);
        this.listenTo(model, 'change:localVars', this.setCarouselComponentsLocal);
        this.listenTo(model, 'change:globalVars', this.setCarouselComponentsGlobal);
        this.listenTo(model, 'change:endpointSelect', this.importDBFields);
        this.listenTo(model, '', this.restoreData());
      },
      async restoreData() {
        try {
          if (this.model.attributes.sluglofty.includes('carouselCartViewer')) {
            if (this.model.attributes.variableHandle) {
              if (this.model.attributes.variableHandle.includes('local')) {
                addTrait(this.model, 'local', this.model.attributes.localVars);
                const getVarData = exhaustiveSearchVariable(this.model.attributes.localVars, 'local');
                await getEndpointInfoFromVariable(this.model, getVarData?.relationDatabase);
              } else {
                addTrait(this.model, 'global', this.model.attributes.globalVars);
                const getVarData = exhaustiveSearchVariable(this.model.attributes.globalVars, 'global');
                await getEndpointInfoFromVariable(this.model, getVarData?.relationDatabase);
              }
            }
            if (this.model.attributes.endpointSelect) {
              getEndpointAnalysisForParams(this.model, this.model.attributes.endpointSelect);
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      updateComponent() {
        // console.log(this.model);
        try {
          if (this.model.attributes.sluglofty.includes('carouselCartViewer')) {
            const getTypeVariable = this.model.getTrait('variableHandle').attributes.value;
            if (getTypeVariable === 'local') {
              restoreComponentToDefault(this.model);
              this.model.removeTrait('globalVars');
              this.model.removeTrait('endpointSelect');
              this.model.removeTrait('buttonCustomEndpointModal');
              addTrait(this.model, 'local', '');
            } else {
              restoreComponentToDefault(this.model);
              this.model.removeTrait('localVars');
              this.model.removeTrait('endpointSelect');
              this.model.removeTrait('buttonCustomEndpointModal');
              addTrait(this.model, 'global', '');
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async setCarouselComponentsLocal() {
        try {
          if (this.model.attributes.sluglofty.includes('carouselCartViewer')) {
            restoreComponentToDefault(this.model);
            addCarouselHTML(this.model, 'localVars');
          }
        } catch (error) {
          //
        }
      },
      async setCarouselComponentsGlobal() {
        try {
          if (this.model.attributes.sluglofty.includes('carouselCartViewer')) {
            restoreComponentToDefault(this.model);
            addCarouselHTML(this.model, 'globalVars');
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async importDBFields() {
        try {
          let dbFromOrigin;
          const {token, idProject} = opts;
          if (this.model.attributes.localVars) {
            dbFromOrigin = exhaustiveSearchVariable(this.model.attributes.localVars, 'local');
          } else {
            dbFromOrigin = exhaustiveSearchVariable(this.model.attributes.globalVars, 'global');
          }
          if (dbFromOrigin) {
            const endpointSelection = this.model.getTrait('endpointSelect');
            const endpointValue = endpointSelection.attributes.value;
            const fieldList = await listFieldsDatabase({
              token,
              idDatabase: dbFromOrigin.relationDatabase,
              idProject,
            });
            // const typeDB = await getOneDatabase({token, _id: dbFromOrigin.relationDatabase});
            const endpoint = await getOneEndpoint({token, _id: endpointValue});
            let htmlAdder = '<div data-gjs-slugLofty="cartRelationData" data-gjs-name="Relacion del Carrito" style="padding: 10px;" >';
            if (endpoint.data.typeFunction === 'list') {
              htmlAdder += '<div data-gjs-slugLofty="itemCartListEndpointDatabase" data-gjs-name="Item List Read" style="padding: 10px;" >';
              const filterFields = fieldList.data.filter((index) => (index.type !== 'cartArray' && index.type !== 'arrayDatabase'));
              htmlAdder += `${databaseFieldsGetComponent(filterFields)}`;
              htmlAdder += '</div>';
              htmlAdder += '</div>';
              const getModelData = this.model.get('components');
              const getMainCart = getModelData.filter((index) => (index.attributes.sluglofty === 'itemCartData'));
              if (getMainCart) {
                getMainCart.append(htmlAdder);
              }
            } else if (endpoint.data.typeFunction === 'customList') {
              const getParamData = await listParamsEndpoint({
                idEndpoint: endpointValue,
                idProject: endpoint.data.idProject,
                token,
              });
              if (getParamData.data.length > 0) {
                const paramValues = getParamData.data.map((index) => ({
                  paramendpoint: {
                    value: index.name,
                    label: index.label,
                    type: index.type,
                    stringDefaultValues: index?.stringDefaultValues || [],
                  },
                  state: index?.stringDefaultValues?.length > 0 ? 'static' : 'useparams',
                }));
                this.model.addAttributes({customParams: paramValues});
                captureDataForCustomEndpointCall(this.model, true, true);
              }
              const listFieldsFromResponse = await listFieldsDatabase({
                token,
                idDatabase: endpoint?.data?.responseDatabase,
                idProject,
              });
              const filterFields = listFieldsFromResponse.data.filter((index) => (index.type !== 'cartArray' && index.type !== 'arrayDatabase'));
              htmlAdder += '<div data-gjs-slugLofty="itemCartListEndpointDatabase" data-gjs-name="Item Listado Relacion" style="padding: 10px;" >';
              htmlAdder += `${databaseFieldsGetComponent(filterFields)}`;
              htmlAdder += '</div>';
              htmlAdder += '</div>';
              const getModelData = this.model.get('components');
              const getMainCartSection = getModelData.find((index) => (index.attributes.sluglofty === 'cartItemInfoRender'));
              const getItemData = getMainCartSection.get('components');
              const getMainCart = getItemData?.find((index) => (index.attributes.sluglofty === 'itemCartData'));
              if (getMainCart) {
                getMainCart.append(htmlAdder);
              }
            }
            // SET DELIMITERS
            const getModelData = this.model.get('components');
            const getMainCartSection = getModelData.find((index) => (index.attributes.sluglofty === 'cartItemInfoRender'));
            const getItemData = getMainCartSection.get('components');
            const getMainCart = getItemData?.find((index) => (index.attributes.sluglofty === 'itemCartData'));
            const listData = getMainCart.get('components');
            listData.forEach((index) => index.set({draggable: (e, d) => validDestinationForComponent(e, d, 'itemCartData')}));
            const getItemsFromRelation = listData?.find((index) => (index.attributes.sluglofty === 'cartRelationData'));
            const relationData = getItemsFromRelation.get('components');
            relationData.forEach((index) => index.set({draggable: (e, d) => validDestinationForComponent(e, d, 'cartRelationData')}));
            const relationFields = relationData?.find((index) => (index.attributes.sluglofty === 'itemCartListEndpointDatabase'));
            const relationComponents = relationFields.get('components');
            relationComponents.forEach((index) => index.set({draggable: (e, d) => validDestinationForComponent(e, d, 'itemCartListEndpointDatabase')}));
          }
        } catch (error) {
          // console.log(error);
        }
      },
    }),
  });
};
