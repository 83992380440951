/* eslint-disable max-len */
import {
  getOneEndpoint,
  listDatabases,
  listFieldsDatabase,
  listParamsEndpoint,
} from 'api-lofty';
import {restoreEndpointTrait, restoreFilterCustomEndpoint} from '../../util/componentDatabaseTraitAndCustom';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  async function restoreFields(model) {
    try {
      const {token, idProject} = opts;
      const idDatabase = model.attributes.dbSelect;
      const endpoint = await getOneEndpoint({token, _id: model.attributes.endpointSelect});
      let fieldList = [];
      if (endpoint.data.typeFunction.includes('custom') && endpoint.data.responseType === 'listdatabase') {
        const dataFields = await listFieldsDatabase({token, idDatabase: endpoint?.data?.responseDatabase, idProject});
        fieldList = dataFields.data;
      } else {
        const dataFields = await listFieldsDatabase({token, idDatabase, idProject});
        fieldList = dataFields.data;
      }
      const valuesForCoordinateSelect = [];
      const getFieldsOfCoordinates = fieldList.filter((index) => (index.type === 'coordinates'));
      if (getFieldsOfCoordinates.length > 0) {
        getFieldsOfCoordinates.forEach((index) => (
          valuesForCoordinateSelect.push({value: index._id, name: index.label})
        ));
        const traitAddition = {
          type: 'select',
          name: 'fieldCoSelect',
          label: 'Campo con Coordenadas',
          changeProp: 1,
          options: valuesForCoordinateSelect,
          default: model?.attributes?.fieldCoSelect || '',
        };
        model.addTrait(traitAddition);
      }
    } catch (error) {
      //
    }
  }

  async function endpointTraitManager(model, fieldData, endpointData) {
    const getFieldsOfCoordinates = fieldData.filter((index) => (index.type === 'coordinates'));
    if (getFieldsOfCoordinates.length > 0) {
      const valuesForCoordinateSelect = [];
      getFieldsOfCoordinates.forEach((index) => (
        valuesForCoordinateSelect.push({value: index._id, name: index.label})
      ));
      const traitAddition = {
        type: 'select',
        name: 'fieldCoSelect',
        label: 'Campo con Coordenadas',
        changeProp: 1,
        options: valuesForCoordinateSelect,
        default: model?.attributes?.fieldCoSelect || '',
      };
      model.addTrait(traitAddition);
    } else {
      // eslint-disable-next-line no-alert
      alert('No hay campos de coordenadas');
      return;
    }
    if (endpointData.data.typeFunction === 'customList') {
      restoreFilterCustomEndpoint(model, endpointData, opts);
      const {token} = opts;
      // componentData.attributes?.attributes?.carouselParams
      const getParamData = await listParamsEndpoint({
        idEndpoint: endpointData.data._id,
        idProject: endpointData.data.idProject,
        token,
      });
      // console.log(getParamData);
      const paramValues = getParamData.data.map((index) => (
        {
          paramendpoint: {
            value: index.name,
            label: index.label,
            type: index.type,
            stringDefaultValues: index?.stringDefaultValues || [],
          },
          state: index?.stringDefaultValues?.length > 0 ? 'static' : 'useparams',
        }));
      model.addAttributes({customParams: paramValues});
    }
  }

  async function handleEndpointForRetrieval(model) {
    try {
      const {token, idProject} = opts;
      const idDatabase = model.attributes.dbSelect;
      const endpoint = await getOneEndpoint({token, _id: model.attributes.endpointSelect});
      if (endpoint.data.typeFunction.includes('custom') && endpoint.data.responseType === 'listdatabase') {
        const fieldList = await listFieldsDatabase({token, idDatabase: endpoint?.data?.responseDatabase, idProject});
        endpointTraitManager(model, fieldList.data, endpoint);
      } else {
        const fieldList = await listFieldsDatabase({token, idDatabase, idProject});
        endpointTraitManager(model, fieldList.data, endpoint);
      }
    } catch (error) {
      // console.log(error);
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        // console.log(el);
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === 'dynamicMapComponent') {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        // console.log(error);
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateButtonState());
        this.listenTo(model, 'change:dbSelect', this.handleDatabaseSelection);
        this.listenTo(model, 'change:endpointSelect', this.handleEndpointSelection);
      },
      async updateButtonState() {
        this.model.removeTrait('dbSelect');
        this.model.removeTrait('endpointSelect');
        this.model.removeTrait('buttonCustomEndpointModal');
        this.model.removeTrait('fieldCoSelect');
        try {
          if (this.model.attributes.sluglofty.includes('dynamicMapComponent')) {
            // this.model.set({droppable: (e) => isValidItemDrop(e)});
            const {token, idProject} = opts;
            // const idProject = opts.idProject;
            // const idPage = opts.idPage;
            const values = [];
            const resData = await listDatabases({token, idProject});
            resData.data.forEach((index) => values.push({value: index._id, name: index.label}));
            const traitAddition = {
              type: 'select',
              name: 'dbSelect',
              label: 'Seleccion de Coleccion',
              changeProp: 1,
              options: values,
              default: '',
            };
            this.model.addTrait(traitAddition);
            if (this.model.attributes?.dbSelect) {
              restoreEndpointTrait(this.model, 'ReadSingleCarousel', opts, this.model?.attributes?.endpointSelect);
            }
            if (this.model?.attributes?.endpointSelect) {
              const endpoint = await getOneEndpoint({token, _id: this.model.attributes.endpointSelect});
              await restoreFields(this.model);
              if (endpoint.data.typeFunction === 'customList') {
                restoreFilterCustomEndpoint(this.model, endpoint, opts);
              }
              // addButtonForVariable(this.model);
            }
            // if (this.model?.attributes?.variableButton) {
            //   addCompatibleVariables(this.model);
            // }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async handleDatabaseSelection() {
        try {
          if (this.model.attributes.sluglofty.includes('dynamicMapComponent')) {
            this.model.removeTrait('endpointSelect');
            this.model.removeTrait('buttonCustomEndpointModal');
            this.model.removeTrait('fieldCoSelect');
            // It works with the attribute of ReadSingleCarousel
            restoreEndpointTrait(this.model, 'ReadSingleCarousel', opts, this.model?.attributes?.endpointSelect);
          }
        } catch (error) {
          //
        }
      },
      async handleEndpointSelection() {
        try {
          if (this.model.attributes.sluglofty.includes('dynamicMapComponent')) {
            this.model.removeTrait('buttonCustomEndpointModal');
            this.model.removeTrait('fieldCoSelect');
            // restoreComponentToDefault(this.model);
            handleEndpointForRetrieval(this.model);
            // addButtonForVariable(this.model);
          }
        } catch (error) {
          //
        }
      },
    }),
  });
};
