/* eslint-disable prefer-destructuring */
/* eslint-disable func-names */
/* eslint-disable no-undef */

import {getOnePageApiPages, listFieldsDatabase, listMethodPaymentProject} from 'api-lofty';
import {databaseFieldsGetComponent} from '../../util/databaseFieldsComponent';
import {restoreComponentToDefault} from '../../util/grapeJsCanvasFunctions';
// import {getAllReadFieldsForParams} from '../../util/databaseFieldsComponent';

export default (editor, opts = {}) => {
  const dc = editor.DomComponents;
  const defaultType = dc.getType('default');
  const defaultView = defaultType.view;

  async function getPaymentMethods(model) {
    const token = opts.token;
    const idProject = opts.idProject;
    const getPageData = await getOnePageApiPages({token, _id: opts.idPage});
    const resMethod = await listMethodPaymentProject({project: idProject, token});
    const optionsData = [];
    let filterData = [];
    if (getPageData.data.authProtocol === 'active' || getPageData.data.authProtocol === 'not required') {
      filterData = resMethod.data.filter((index) => (index.type === 'createOrderShoppingcart' && index?.haveAuth));
    } else {
      filterData = resMethod.data.filter((index) => (index.type === 'createOrderShoppingcart'));
    }
    filterData.forEach((index) => {
      optionsData.push({
        name: index.label,
        value: index._id,
        objectData: index,
      });
    });
    const defaultValue = model.attributes.methodPayFunction || '';
    const traitPaymentMethods = {
      type: 'select',
      name: 'methodPayFunction',
      label: 'Metodo de Pago',
      changeProp: 1,
      options: optionsData,
      default: defaultValue,
    };
    model.addTrait(traitPaymentMethods);
  }

  async function getFieldsFromPayDatabase(model, databaseId) {
    const token = opts.token;
    const idProject = opts.idProject;
    const resFields = await listFieldsDatabase({idProject, token, idDatabase: databaseId});
    const filterFields = resFields.data.filter((index) => (index.type !== 'cartArray' && index.type !== 'arrayDatabase' && index.type !== 'user'));
    const resFieldsReact = databaseFieldsGetComponent(filterFields);
    let htmlAdder = '<form data-gjs-resizable="{bc: 1}" style="padding: 10px;" data-gjs-slugLofty="formPayCart" data-gjs-name="Formulario Carrito" title="addFormDatabase">';
    htmlAdder += `
    ${resFieldsReact}
    <button
      type="submit"
      data-gjs-slugLofty="formAddDatabaseButton"
      data-gjs-name="Boton Agregar a Coleccion de Datos"
      data-gjs-resizable="{bc: 1}"
    >
      <div data-gjs-resizable="{bc: 1}" data-gjs-slugLofty="standardButtonText" data-gjs-name="Texto Estandar de Boton" data-gjs-draggable="false">Submit</div>
    </button>
    </form>
    `;
    model.append(htmlAdder);
  }

  async function getVariables(model, originId) {
    const varData = [];
    const localVars = opts.localVars;
    const globalVars = opts.globalVars;
    localVars.filter((index) => (index.type === 'cartArray' && index.idDatabase === originId))
      .forEach((variable) => {
        varData.push({name: `${variable.label}--local`, value: variable._id, originId: variable.idDatabase});
      });
    globalVars.filter((index) => (index.typeValue === 'cartArray' && index.idDatabase === originId))
      .forEach((variable) => {
        varData.push({name: `${variable.label}--global`, value: variable._id, originId: variable.idDatabase});
      });
    const traitAddition = {
      type: 'select',
      name: 'cartMethodPay',
      label: 'Variable para Carrito',
      changeProp: 1,
      options: varData,
      default: model?.attributes?.cartMethodPay,
    };
    model.addTrait(traitAddition);
  }

  async function captureFieldForVars(model, idField, idDatabase) {
    const token = opts.token;
    const idProject = opts.idProject;
    const resFields = await listFieldsDatabase({idProject, token, idDatabase});
    const filterByID = resFields.data.find((index) => (index._id === idField));
    getVariables(model, filterByID?.relationDatabase);
  }

  async function SeekInformationForFields(model) {
    const getSelectedData = model.getTrait('methodPayFunction').attributes.value;
    const getOption = model.getTrait('methodPayFunction').attributes.options;
    const filterData = getOption.find((obj) => (obj.value === getSelectedData));
    if (filterData) {
      getFieldsFromPayDatabase(model, filterData.objectData?.idDatabase);
      captureFieldForVars(
        model,
        filterData.objectData?.shoppingCartField,
        filterData.objectData?.idDatabase,
      );
    }
  }

  async function RestoreVarData(model) {
    const getSelectedData = model.attributes.methodPayFunction;
    const getOption = model.getTrait('methodPayFunction').attributes.options;
    const filterData = getOption.find((obj) => (obj.value === getSelectedData));
    if (filterData) {
      captureFieldForVars(
        model,
        filterData.objectData?.shoppingCartField,
        filterData.objectData?.idDatabase,
      );
    }
  }

  dc.addType(opts.name, {
    model: {
      defaults: {
        traits: [],
      },
    },

    // eslint-disable-next-line consistent-return
    isComponent: (el) => {
      try {
        const attr = el.attributes;
        if (attr['data-gjs-sluglofty'] && attr['data-gjs-sluglofty'].value === 'paymentMethodDiv') {
          return {
            type: opts.name,
          };
        }
      } catch (error) {
        //
      }
    },

    view: defaultView.extend({
      init({model}) {
        this.listenTo(model, '', this.updateButtonState());
        this.listenTo(model, 'change:methodPayFunction', this.seekData);
      },
      async updateButtonState() {
        try {
          if (this.model.attributes.sluglofty.includes('paymentMethodDiv')) {
            await getPaymentMethods(this.model);
            if (this.model.attributes.methodPayFunction) {
              await RestoreVarData(this.model);
            }
          }
        } catch (error) {
          // console.log(error);
        }
      },
      async seekData() {
        try {
          if (this.model.attributes.sluglofty.includes('paymentMethodDiv')) {
            restoreComponentToDefault(this.model);
            await SeekInformationForFields(this.model);
          }
        } catch (error) {
          //
        }
      },
    }),
  });
};
