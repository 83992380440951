/* eslint-disable no-alert */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/function-component-definition */
import React, {useState, useEffect} from 'react';
import {
  Dialog, Box, TextField,
  Button, Typography, MenuItem,
  Checkbox, FormControlLabel,
} from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import {listDatabases, listFieldsDatabase} from 'api-lofty';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#242424',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  iconButtonStyle: {
    color: 'rgba(255, 255, 255, 1)',
  },
  messageContainer: {
    display: 'flex',
    flexDirection: 'column',
    color: 'rgba(243, 243, 243, 1)',
    margin: '10px',
    padding: '15px',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    borderRadius: '5px',
  },
  buttonAreaContainer: {
    margin: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  buttonStyle: {
    width: '100px',
    fontWeight: 'bold',
  },
}));

const ModalAddVariable = ({
  open, onClose, handleCloseAddVariable, type, addVariable,
  token, idProject,
}) => {
  const [typeField, setTypeField] = useState('');
  const [nameVariable, setNameVariable] = useState('');
  const [labelVariable, setLabelVariable] = useState('');
  const [safeLocal, setSafeLocal] = useState(false);
  const [databasesWithRelation, setDatabasesWithRelation] = useState([]);
  const [withRelation, setWithRelation] = useState(false);
  const [relationDatabase, setRelationDatabse] = useState('');
  const [database, setDatabase] = useState('');
  const [databases, setDatabases] = useState([]);
  const [isOptional, setIsOptional] = useState(false);
  const typesOfFields = [
    {label: 'Texto', value: 'string'},
    {label: 'Numero', value: 'number'},
    {label: 'Booleano', value: 'boolean'},
    {label: 'Lista de Base de Datos', value: 'arrayDatabase'},
    {label: 'Dato de base de datos', value: 'database'},
    {label: 'Carrito de Compras', value: 'cartArray'},
  ];
  const classes = useStyle();

  useEffect(() => {
    const handleData = async () => {
      try {
        const res = await listDatabases({token, idProject});
        setDatabases(res.data);
        const dbWithRelations = [];
        for (const collection of res.data) {
          const fields = await listFieldsDatabase({token, idDatabase: collection._id, idProject});
          const findField = fields.data.find((index) => (index.relationDatabase));
          if (findField) {
            dbWithRelations.push({
              idDatabase: collection._id,
              infoFields: findField,
              nameDb: collection.label,
              relationId: findField.relationDatabase,
            });
          }
        }
        setDatabasesWithRelation(dbWithRelations);
      } catch (error) {
        // console.log(error)
      }
    };
    handleData();
  }, [type]);

  function toAdd() {
    try {
      if (withRelation && (!relationDatabase || relationDatabase === '')) {
        alert('La relacion de datos no fue seleccionada');
        return;
      }
      addVariable({
        nameVariable,
        labelVariable,
        typeField,
        type,
        safeLocal,
        idDatabase: database,
        relationDatabase,
        isOptional,
      });
    } catch (error) {
      // console.log(error);
    }
  }

  function setRelationStatus(status) {
    if (!status) {
      setRelationDatabse('');
      setWithRelation(status);
    } else {
      setWithRelation(status);
    }
  }
  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Variables de Proyecto
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{backgroundColor: '#242424', color: '#fff'}}>
        <Box component="div" style={{margin: '15px', padding: '5px'}}>
          <Box className={classes.messageContainer}>
            <Typography
              style={{
                position: 'relative',
                bottom: '10px',
              }}
            >
              {`Agregar Variable ${type} para proyecto`}
            </Typography>
          </Box>
          <Box>
            <div className="my-4">
              <TextField
                fullWidth
                name="label"
                type="text"
                label="Etiqueta"
                variant="standard"
                value={labelVariable}
                onChange={(e) => setLabelVariable(e.target.value)}
                style={{backgroundColor: '#D9D9D9', color: '#fff'}}
              />
            </div>
            <div className="my-4">
              <TextField
                fullWidth
                name="name"
                type="text"
                label="Slug"
                variant="standard"
                value={nameVariable}
                onChange={(e) => setNameVariable(e.target.value)}
                style={{backgroundColor: '#D9D9D9', color: '#fff'}}
              />
            </div>
            <div className="my-4">
              <TextField
                onChange={(e) => setTypeField(e.target.value)}
                value={typeField}
                select
                fullWidth
                name="type"
                type="text"
                label="Tipo"
                variant="standard"
                style={{backgroundColor: '#D9D9D9', color: '#fff'}}
              >
                {
                  typesOfFields.map(({label, value}) => (
                    <MenuItem value={value} key={value}>
                      {label}
                    </MenuItem>
                  ))
                }
              </TextField>
            </div>
            {
              (typeField === 'arrayDatabase' || typeField === 'database' || typeField === 'cartArray') ? (
                <div className="my-4">
                  <TextField
                    onChange={(e) => setDatabase(e.target.value)}
                    value={database}
                    select
                    fullWidth
                    name="type"
                    type="text"
                    label="Tipo"
                    variant="standard"
                    style={{backgroundColor: '#D9D9D9', color: '#fff'}}
                  >
                    {
                      databases.map(({label, _id}) => (
                        <MenuItem value={_id} key={_id}>
                          {label}
                        </MenuItem>
                      ))
                    }
                  </TextField>
                </div>
              ) : <></>
            }
            {type === 'global' && (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={safeLocal}
                    onChange={() => setSafeLocal(!safeLocal)}
                    name="safeLocal"
                    color="primary"
                  />
              )}
                label="Guardar en Local?"
                // style={{backgroundColor: '#D9D9D9', color: '#fff'}}
              />
            )}
            {typeField === 'cartArray' && database && (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={withRelation}
                    onChange={() => setRelationStatus(!withRelation)}
                    name="safeLocal"
                    color="primary"
                  />
              )}
                label="Utilizar con relacion de base de datos?"
                // style={{backgroundColor: '#D9D9D9', color: '#fff'}}
              />
            )}
            {withRelation && (
              <div className="my-4">
                <TextField
                  onChange={(e) => setRelationDatabse(e.target.value)}
                  value={relationDatabase}
                  select
                  fullWidth
                  name="type"
                  type="text"
                  label="Relacion con:"
                  variant="standard"
                  style={{backgroundColor: '#D9D9D9', color: '#fff'}}
                >
                  {
                  databasesWithRelation.filter(
                    (index) => (index?.relationId === database),
                  ).map(({nameDb, idDatabase}) => (
                    <MenuItem value={idDatabase} key={idDatabase}>
                      {nameDb}
                    </MenuItem>
                  ))
                }
                </TextField>
              </div>
            )}
            {typeField === 'cartArray' && withRelation && (
              <FormControlLabel
                control={(
                  <Checkbox
                    checked={isOptional}
                    onChange={() => setIsOptional(!isOptional)}
                    name="isOptionalRel"
                    color="primary"
                  />
              )}
                label="Es Relacion Opcional?"
                // style={{backgroundColor: '#D9D9D9', color: '#fff'}}
              />
            )}
          </Box>
          <Box className={classes.buttonAreaContainer}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => { if (handleCloseAddVariable) { handleCloseAddVariable(); } }}
              className={classes.buttonStyle}
            >
              Cerrar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={classes.buttonStyle}
              onClick={(e) => toAdd(e)}
            >
              Guardar
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ModalAddVariable;
