import {mapIconSvg} from '../basicBlocks/iconsSVG';

export default (editor, opts) => {
  const c = opts;
  // const {blocks} = c;
  const bm = editor.BlockManager;
  bm.add(opts.name, {
    label: `
      <div style="display: flex; justify-content: center">
      ${mapIconSvg}
    </div>
      </div>
      <div class="gjs-block-label">
        ${c.labelComponent}
      </div>`,
    category: c.category,
    attributes: {title: 'Mapa Dinamico. ', id: 'component-dynamicmap-lofty-editor'},
    content: `
      <div
        data-gjs-slugLofty="dynamicMapComponent"
        data-gjs-name="Mapa Dinamico"
        data-gjs-droppable="false"
        style="padding: 10px; display: block; width: 200px"
        data-gjs-resizable="{bc: 1}"
      >
        <iframe
        data-gjs-stylable="false"
            data-gjs-draggable="false"
            data-gjs-droppable="false"
            data-gjs-resizable="false"
            data-gjs-editable="false"
            data-gjs-highlightable="false"
            data-gjs-selectable="false"
        width="100%" height="300px" style="border:0;"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434508619!2d144.9630576153177!3d-37.81627977975171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0x5045675218ce720!2sMelbourne%20VIC%2C%20Australia!5e0!3m2!1sen!2sus!4v1630246787231!5m2!1sen!2sus"></iframe>
      </div
    `,
  });
};
