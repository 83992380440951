import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import {
  Box, Dialog, DialogContent, DialogTitle,
  TextField, MenuItem, Button,
} from '@material-ui/core';

const useStyle = makeStyles(() => ({
  titleArea: {
    padding: 0,
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#242424',
    padding: '10px',
  },
  titleStyle: {
    marginLeft: '5px',
    color: 'rgba(255, 255, 255, 1)',
  },
  inputInfoContainer: {
    padding: '10px',
  },
  inputTitleInfo: {
    fontSize: '15px',
    fontWeight: 600,
    color: '#000',
  },
}));

export default function ModalManageVarsCarouselRelation({
  data,
  variables,
  editParamInput,
  open,
  onClose,
  // numberOfCarousels,
  savedInfo,
}) {
  const classes = useStyle();
  const [inputs, setInputs] = useState([]);

  useEffect(() => {
    setInputs(data);
  });

  function retrieveDataValue(keyValue) {
    const findDataInSave = savedInfo?.find((index) => (
      index._id === keyValue));
    if (findDataInSave) {
      return findDataInSave.value;
    }
    return '';
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogTitle className={classes.titleArea}>
        <Box className={classes.titleContainer}>
          <Box className={classes.titleStyle}>
            Variables de Relacion de Datos de Carousel
          </Box>
        </Box>
      </DialogTitle>
      <DialogContent style={{background: '#242424', color: '#fff'}}>
        <Box
          component="div"
          style={{
            margin: '15px', padding: '5px', height: '400px', overflowY: 'scroll',
          }}
        >
          {
            inputs?.map((value) => (
              <Box key={value._id} className={classes.inputInfoContainer}>
                <Box component="div" className={classes.inputInfoContainer} key={value._id}>
                  <Box component="div" className={classes.inputTitleInfo}>
                    {`Carousel: ${value._id}`}
                  </Box>
                  <div className="my-4">
                    <TextField
                      select
                      fullWidth
                      name="type"
                      onChange={(e) => editParamInput(value._id, e.target.value)}
                      value={retrieveDataValue(value._id)}
                      type="text"
                      label="Variable:"
                      variant="standard"
                      style={{backgroundColor: '#D9D9D9', color: '#fff'}}
                    >
                      {
                          variables?.map(({value, name}) => (
                            <MenuItem value={value} key={value}>
                              {name}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                  </div>
                </Box>
              </Box>
            ))
          }
        </Box>
        <Box className={classes.buttonAreaContainer}>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClose}
            className={classes.buttonStyle}
          >
            Cerrar
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
